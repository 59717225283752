<template>
    <div>
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
        <!-- 获取openId -->
    </div>
</template>
<script>
export default {
    data(){
        return{
            isLoading:true
        }
    },
    created(){
      
        //  let appid = window.g.appid;
     
        // let url = "http://t7gk87.natappfree.cc/#/getOpenId";
        // // console.log(encodeURIComponent(url));
        // url = encodeURIComponent(url);
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
      
      let url = location.href
    //  let url = 'http://t7gk87.natappfree.cc/?code=071k3dml2Dbll94WoEkl2KUp200k3dmu&state=1#/getOpenId'
     let code = url.split('code=')[1].split('&state')[0]
    //  console.log(code);

     this.$api.order.getOpenId(code).then(res=>{
         sessionStorage.openId = res

         this.$router.replace('/my')

        //  if(localStorage.storeId){
        //      this.$router.push({ path: "/indexAlone" })
        //  }else{
        //      this.$router.push({ path: "/index" })
        //  }
     })
    }
    
}
</script>